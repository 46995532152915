import React from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";

const PaypalCheckoutButton = ({ product, setRequestSent, setTowDialogOpen, handleSubmit }) => {
  const style = {
    layout: "vertical",
    tagline: false,
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: product.description,
          amount: {
            value: product.price,
          },
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    const order = await actions.order.capture();
    setRequestSent(true);
    setTowDialogOpen(false);
    handleSubmit();
    console.log('order', order)
  };

  return (
    <div style={{ height: "75px" }}>
      <PayPalScriptProvider
        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
      >
        <PayPalButtons
          style={style}
          forceReRender={[style]}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default PaypalCheckoutButton;
