import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";
import { setDevice } from '../redux/appSettingsSlice'

function AppWrapper(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.between("md"));
    // use effect that deterines what device the user is on and puts it into a single string
  useEffect(() => {
    if (mobile) {
      dispatch(
        setDevice('mobile')
      );
    } else if (desktop) {
      dispatch(
        setDevice('desktop')
      );
    } else if (tablet) {
      dispatch(
        setDevice('tablet')
      );
    }
  }, [mobile, desktop, tablet, dispatch]);
  
  return props.children;
}
  
export default AppWrapper;

