import { Box, TextField } from "@mui/material";

const SignUp = ({ setEmail, setConfirmPassword, setPassword }) => {
  const formPadding = {pb: 2};
  return (
    <Box sx={{ px: 2 }}>
      <TextField
        autoFocus
        id="email-signup"
        label="Email"
        variant="outlined"
        fullWidth
        onChange={(e) => setEmail(e.target.value)}
        sx={formPadding}
      />
      <TextField
        id="password-signup"
        label="Password"
        variant="outlined"
        type="password"
        fullWidth
        onChange={(e) => setPassword(e.target.value)}
        sx={formPadding}
      />
      <TextField
        id="confirm-password-signup"
        label="Confirm Password"
        variant="outlined"
        type="password"
        fullWidth
        onChange={(e) => setConfirmPassword(e.target.value)}
        sx={formPadding}
      />
    </Box>
  )
};

export default SignUp;
