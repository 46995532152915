import { Box, Button, Typography, LinearProgress, Stack, Dialog } from '@mui/material'
import { useEffect, useState } from 'react'
import SignUp from './SignUp';
import SubscriptionOptions from './SubscriptionOptions';
import Payment from './Payment';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { auth, db } from '../../../config/firebase';
import Confirmation from './Confirmation';
import { motion } from 'framer-motion';

const Register = ({ setLoggedIn }) => {
  const [ open, setOpen ] = useState(false)
  const [ step, setStep ] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const formPadding = {pb: 2}

  useEffect(() => {
    console.log('The open state is: ', open)
  }, [open])


  //firebase ref to users collection
  const usersRef = collection(db, 'users')

  const addUserToDB = async () => {
    try {
      const docRef = await addDoc(usersRef, {
        id: auth.currentUser.uid,
        email: email,
        role: 'customer'
      })
      console.log('Document written with ID: ', docRef.id)
    } catch (error) {
      console.error('Error adding document: ', error)
    }
  }

  const handleSignUp = async () => {
    try {
      console.log('before creating user, the open state is: ', open)
      await createUserWithEmailAndPassword(auth, email, password)
      console.log('after creating user, the open state is: ', open)
      setStep(step + 1)
      addUserToDB()
    } catch (error) {
      console.error(error);
      const errorMessage = error.message.replace('Firebase: ', '')
      alert(errorMessage)
    }
  }

  const handleClose = () => {
    console.log('handleClose fired')
    if (step === FormSteps.length - 1) {
      setOpen(false);
    }
    // setLoggedIn(true);
  }

  const passwordError = () => {
    if(password && password !== confirmPassword && confirmPassword !== '') {
      return true;
    }
  }

  const FormSteps = [
    { 
      title: 'Sign Up',
      content: <SignUp setEmail={setEmail} setPassword={setPassword} setConfirmPassword={setConfirmPassword}/>,
      footer: (
        <Stack>
          {passwordError() && <Typography color="red"> {passwordError() && 'Passwords do not match!'} </Typography>}
          <Button onClick={handleSignUp} disabled={passwordError()}>Create Account</Button>
        </Stack>
      )
    },
    {
      title: 'Subscription Options',
      content: <SubscriptionOptions />,
      footer: <Button onClick={() => setStep(step + 1)}>Continue to Payment</Button>
    },
    {
      title: 'Payment',
      content: <Payment />,
      footer: (
        <Stack direction={'row'}>
          <Button onClick={() => prevStep(step)}>Back</Button>
          <Button onClick={() => nextStep(step)}>Continue</Button>
        </Stack>
      )
    },
    {
      title: 'Confirmation',
      content: <Confirmation />
    }
  ]
  

  const nextStep = (step) => {
    setStep(step + 1)
  }

  const prevStep = (step) => {
    setStep(step - 1)
  }
  

  return (
    <>
      <Button 
        onClick={() => setOpen(true)}
        component={motion.div}
        variant="contained"
        color="success"
        sx={{ p: 2.5, mt: 1.5, borderRadius: 3 }}
        initial={{ y: -400 }}
        animate={{
          y: 0,
          transition: {
            delay: 0.5,
            duration: 1,
            type: "spring",
            bounce: 0.3,
          },
        }}>
        <Typography color="#333131" fontFamily={"Designer"}>
          Sign Up Now!
        </Typography>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <Box>
            <Box sx={{ py: 1 }}>
                <LinearProgress variant='determinate' sx={{ height: 10 }} value={step/(FormSteps.length - 1)*100}/>
            </Box>
            <Box>
              <Box>
                <Typography textAlign={'center'} sx={formPadding}>{FormSteps[step].title}</Typography>
              </Box>
              <Box sx={{p: 2}}>
                {FormSteps[step].content}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2}}>
                { FormSteps[step].footer }
              </Box>
            </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default Register