import { List, ListItem, ListItemIcon, Typography } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const SubHeadings = () => {
  const subheadingItems = [
    "Instant Requests",
    "Precise Location Tracking",
    "Experienced Tow Truck Drivers",
    "24/7 Availability",
  ];

  return (
    <List sx={{ color: "white", display: "flex", flexDirection: "column" }}>
      {subheadingItems.map((item, index) => (
        <ListItem
          key={index}
          sx={{ display: "list-item", mt: -1, textAlign: "center" }}
        >
          <ListItemIcon>
            <EmojiEventsIcon
              sx={{
                color: "white",
                fontSize: "small",
                alignSelf: "center",
                pr: 1,
              }}
            />
            <Typography
              sx={{
                color: "white",
                fontFamily: "Archivo Black",
                textAlign: "center",
              }}
            >
              {item}
            </Typography>
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};

export default SubHeadings;
