import { Button, Dialog, TextField, Typography, Box } from "@mui/material";
import { useState } from "react";
import { motion } from "framer-motion";
import { auth } from "../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = ({ setLoggedIn }) => {
  const [open, setOpen] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const formPadding = {pb: 2};

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, loginEmail, password);
      setLoggedIn(true);
      setOpen(false);
      console.log('The user has just logged in', )
    } catch (error) {
      console.error(error);
      const errorMessage = error.message.replace("Firebase: ", "");
      alert(errorMessage);
    }
  };

  return (
    <>
      <Button
        component={motion.div}
        color="success"
        onClick={() => setOpen(true)}
        initial={{ y: -200 }}
        sx={{ position: 'absolute', top: 0, right: 0, mb: 1.5, mr: 1.5 }}
        animate={{
          y: 0,
          transition: {
            delay: 0.5,
            duration: 1,
            type: "spring",
            bounce: 0.3,
          },
        }}
      >
        <Typography color="white" sx={formPadding} fontFamily={"Montserrat"}>
          Log In
        </Typography>
      </Button>
      <Dialog
        component={motion.div}
        initial={{ y: -600 }}
        animate={{ y: 0, transition: { duration: 0.2 } }}
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
      >
        <Box>
          <Typography variant="h5" textAlign={'center'} color="#333131" fontFamily={"Designer"}>
            Please enter your login credentials
          </Typography>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            fontFamily={'Montserrat'}
            onChange={(e) => setLoginEmail(e.target.value)}
            fullWidth
            sx={formPadding}
          />
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={formPadding}
          />
        </Box>
        <Button onClick={handleLogin}>Login</Button>
      </Dialog>
    </>
  );
};

export default Login;
