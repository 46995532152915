import { Typography } from '@mui/material'
import React from 'react'

const Confirmation = () => {
  return (
    <Typography textAlign={'center'} sx={{ my: 5 }}>
        Congratulations! You have successfully registered for a subscription.
    </Typography>
  )
}

export default Confirmation