import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Typography, Dialog } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import RequestTow from "../requestTow/RequestTow";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";
import CustomerBackground from "../../assets/images/CustomerBackground.png";
import { motion } from "framer-motion";
import Reviews from "./reviews";
import Register from "./registration/Register";
import Login from "./Login";
import Heading from "./Heading";
import SubHeadings from "./SubHeadings";

const CustomerView = () => {
  const currentUser = useContext(AuthContext);
  console.log(currentUser?.currentUser?.email)
  const [loggedIn, setLoggedIn] = useState(currentUser?.currentUser?.email ? true : false);
  const [open, setOpen] = useState(false);
  const mainBoxStyle = {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${CustomerBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backGroundRepeat: "no-repeat",
    pt: 4,
    display: "flex",
    justifyContent: "center",
  };

  // useEffect(() => {
  //   currentUser?.currentUser?.email ? setLoggedIn(true) : setLoggedIn(false);
  // }, [currentUser]);

  const logout = async () => {
    try {
      await signOut(auth);
      setLoggedIn(false);
    } catch (error) {
      console.error(error);
    }
  };
  // my test comment


  // testing git changes

  // asdasd
  // asdasdasd
  // one more comment

  return (
    <Box sx={mainBoxStyle}>
      <Box sx={{ pt: 1 }}>
        {currentUser?.currentUser?.email ? <Heading currentUser={currentUser} logout={logout} /> : <Login setLoggedIn={setLoggedIn}/>}
        <Typography
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1.5 } }}
          color="white"
          fontFamily={"Antihero"}
          fontSize={"1.5rem"}
        >
          Welcome To
        </Typography>
        <Typography
          variant="h4"
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.5, duration: 1.5 } }}
          color="white"
          fontFamily={"Antihero"}
          fontSize={"2.5rem"}
          sx={{ mt: 0.5 }}
          gutterBottom
        >
          Tow Call
        </Typography>
        <SubHeadings />
        <Box sx={{ px: 3, mt: 0.5 }}>
          <Typography variant="p" color="white" fontStyle="italic">
            No Waiting on hold or explaining your location over the Phone! We
            use GPS to pinpoint your exact Location Ensuring Fast and Accurate
            service!
          </Typography>
        </Box>
        {!loggedIn && (
          // <>
          // <Box sx={{ background: 'radial-gradient(circle, #26D7FF, transparent)', mt: 1, pt: 2 }}>
          //   <Typography color='white' fontFamily={'Designer'}>Sign up or log in to take advantage of membership perks and discounts!</Typography>
          //   <Button sx={{ color: 'white', textDecoration: 'underline', fontSize: '1.2rem', fontFamily: 'Designer'  }}>Click Here</Button>
          // </Box>
          // <Typography variant="h5" color="white" fontFamily={"Designer"}>OR</Typography>
          // <RequestTow />
          // </>
          <Box>
            <Register setLoggedIn={setLoggedIn} open={open} setOpen={setOpen} />
            <Typography variant="h4" color="white" fontFamily={"Designer"}>
              OR
            </Typography>
            <RequestTow />
          </Box>
        )}
        {loggedIn && (
          <Box>
            <RequestTow />
          </Box>
        )}
        <Reviews />
      </Box>
    </Box>
  );
};

export default CustomerView;
