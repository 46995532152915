import React from 'react'
import { Avatar, Box, Grid, Typography } from '@mui/material'

const Reviews = () => {
  return (
    <Box sx={{ mb: 1, px: 2, backgroundColor: 'rgb(0,0,0,0.4)', bottom: 0 }}>
        {/* <Typography variant="h5" color='white' fontFamily={'Designer'} textAlign={'center'}>
            reviews
        </Typography> */}
        <Grid container sx={{ mt: 1 }}>
            <Grid item xs={2} >
                <Avatar
                    alt="customer avatar"
                    sx={{ width: 52, height: 52, mt: 2 }}
                    />
            </Grid>
            <Grid item xs={10} sx={{ pl: 2 }}>
                <Typography variant='p' fontSize={'.8rem'} color='white' textAlign={'center'}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu mollis sem. Curabitur euismod, nisl eget aliquam ultricies, nunc nunc aliquet nunc
                </Typography>
            </Grid>
        </Grid>
    </Box>
  )
}

export default Reviews