import { PayPalScriptProvider, usePayPalScriptReducer, PayPalButtons } from "@paypal/react-paypal-js";
import './App.css';
import React, { useState, useEffect } from "react";
import CustomerView from './components/customerView/CustomerView';
import PaypalCheckoutButton from "./components/paypal/PaypalCheckoutButton";

function App() {
  return (
    <PayPalScriptProvider options={{ "client-id": "test" }}>
      <div className="App">
        {/* <PayPalButtons style={{ layout: "horizontal" }} /> */}
        {/* <PaypalCheckoutButton /> */}
        <CustomerView />
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
