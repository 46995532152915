import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { db } from '../config/firebase';
import { getDocs, collection } from 'firebase/firestore';

const towsCollectionRef = collection(db, 'tows');

export const getTowRequests = createAsyncThunk(
    'tow/getTowRequests',
    async () => {
        try {
            const data = await getDocs(towsCollectionRef);
            const tows = data.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
                }));
                console.log(tows)
            return tows;
        } catch (error) {
            console.error(error);
        }
        // const response = await axios.get('http://localhost:5100/tow');
        // return response.data;
    }
);

export const addTowRequest = createAsyncThunk(
    'tow/addTowRequest',
    async (towRequest) => {
        const response = await axios.post('http://localhost:5100/tow', towRequest);
        return response.data;
    }
);

const towsSlice = createSlice({
    name: 'tows',
    initialState: {
        tows: [],
        status: null,
    },
    extraReducers: (builder) => {
        builder.addCase(getTowRequests.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getTowRequests.fulfilled, (state, action) => {
            state.tows = action.payload;
            state.status = 'success';
        })
        .addCase(addTowRequest.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(addTowRequest.fulfilled, (state, action) => {
            state.tows.push(action.payload);
            state.status = 'success';
        });
    }
});

export default towsSlice.reducer;