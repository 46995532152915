import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, Antihero, Archivo Black, Designer, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: 'Antihero';
            src: local('Antihero'), url(/assets/fonts/Antihero.ttf) format('truetype');
        }
        @font-face {
            font-family: 'Archivo Black';
            src: local('Archivo Black'),url(/assets/fonts/ArchivoBlack-Regular.ttf) format('truetype');
        }
        @font-face {
            font-family: 'Designer';
            src: local('Designer'),url(/assets/fonts/Designer.otf) format('truetype');
        }
        @font-face {
            font-family: 'Montserrat';
            src: local('Montserrat'),url(/assets/fonts/Montserrat-Regular.ttf) format('truetype');
        }
      `,
    },
  },
});

export default theme;
