import { createSlice } from "@reduxjs/toolkit";

export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState: {
    device: "desktop",
  },
  reducers: {
    setDevice: (state, action) => {
      state.device = action.payload;
    },
  },
});

export const { setDevice } = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
