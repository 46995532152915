import { useState } from "react";
import { motion } from "framer-motion";
import { Box, Dialog, Typography, Button } from "@mui/material";
import NewTowForm from "./NewTowForm";

const RequestTow = () => {
  const [towDialogOpen, setTowDialogOpen] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  return (
    <>
		<Button
        component={motion.div}
        variant="contained"
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #B9FF4D, #1B46B3)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #B32F12, #1B46B3)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #195AFF, #1B46B3)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #B32F12, #33FFB5)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #36B33F, #26D6FF)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #40FF4E, #26D6FF)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #FF9B40, #19D3FF)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #FF9B40, #19D3FF)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #FF9B40, #4DFF59)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #FF9B40, #4DFF59)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #FF4419, #FFEF4D)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #19FFAC, #FFEF4D)' }}
        // sx={{ p: 2.5, mb: 1.5, borderRadius: 3, background: 'linear-gradient(45deg, #19FFAC, #FFEF4D)' }}
        sx={{ p: 2.5, mb: 1.5, borderRadius: 3 }}
        onClick={() => setTowDialogOpen(true)}
        initial={{ y: 400 }}
				color='success'
        animate={{
          y: 0,
          transition: {
            delay: 0.5,
            duration: 1,
            type: "spring",
            bounce: 0.3,
          },
        }}
      >
        <Typography 
					// color="white" 
					color="#333131" 
					fontFamily={"Designer"}>
          Request Tow
        </Typography>
      </Button>
      <Dialog
        component={motion.div}
        initial={{ y: -600 }}
        animate={{ y: 0, transition: { duration: 0.2 } }}
        open={towDialogOpen}
        fullWidth
        onClose={() => setTowDialogOpen(false)}
      >
        <Box>
          <NewTowForm
            setTowDialogOpen={setTowDialogOpen}
            setRequestSent={setRequestSent}
          />
        </Box>
      </Dialog>
      <Dialog
        open={requestSent}
        fullWidth
        onClose={() => setRequestSent(false)}
      >
        <Box>
          <Typography variant="h4" sx={{ p: 3 }}>
            Thank you for your request! A Tow Truck Driver will be with you soon
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default RequestTow;
