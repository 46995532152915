import React from 'react'
import { Box, Button, Typography } from '@mui/material'

const Heading = ({ currentUser, logout }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: -5, mb: 2 }}>
          <Typography variant='p' color='white' textAlign={'end'}>Welcome, {currentUser?.currentUser?.email}</Typography>
          <Button sx={{ color: 'white', alignSelf: 'flex-end'}} onClick={logout}>Sign Out</Button>
        </Box>
  )
}

export default Heading