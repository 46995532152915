import { configureStore } from "@reduxjs/toolkit";
import towReducer from "./towSlice";
import appSettingsReducer from "./appSettingsSlice";

export const store = configureStore({
    reducer: {
        tows: towReducer,
        appSettings: appSettingsReducer,
    },
    // disable for production
    devTools: true,
});
