import React, { useState } from "react";
import { Box, Button, Stack, TextField, Typography, CircularProgress } from "@mui/material";
import { db } from '../../config/firebase'
import { addDoc, collection } from "firebase/firestore";
import PaypalCheckoutButton from "../paypal/PaypalCheckoutButton";

const NewTowForm = ({ setTowDialogOpen, setRequestSent }) => {
  const product = {
    name: "Tow",
    price: 150
  }
   // firebase ref
  const towRequestsRef = collection(db, "tows");
  // form state
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [carDescription, setCarDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState({});
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);
  const [price, setPrice] = useState(0);
  const formPadding = {pb: 2};

  const mapsAPI = process.env.REACT_APP_MAPS_API;
  // get location function
  const getLocation = () => {
    setIsFetchingLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
      setLocation({ error: "Geolocation not supported" });
    }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLocation({ latitude, longitude });
      getAddress(latitude, longitude);
      setIsFetchingLocation(false);
    }

    function error() {
      alert("Unable to retrieve your location");
      setIsFetchingLocation(false);
    }
  };

  // Get address from lat and long
  const getAddress = async (lat, long) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${mapsAPI}`
    );
    const result = await response.json();
    const addressString = result["results"][0]["formatted_address"].toString();
    setAddress(addressString);
    getPrice();
    console.log(addressString);
  };

  // Function to determine price of tow
  const getPrice = () => {
    setPrice(150);
  };
  // Send new tow request to server
  const sendRequest = async () => {
    try {
      const newRequest = await addDoc(towRequestsRef, {
      name,
      phone,
      car_description: carDescription,
      notes,
      location,
      address,
      isActive: true,
      time: new Date().toLocaleString()
    });
    alert('Tow Request Sent!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = () => {
    sendRequest();
    setTowDialogOpen(false);
    setRequestSent(true);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        spacing={0}
        direction={"column"}
        display="flex"
        alignItems={"center"}
      >
        <Typography variant="h6" component="div" gutterBottom>
          Details:
        </Typography>
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          sx={formPadding}
        />
        <TextField
          id="phone"
          label="Phone"
          variant="outlined"
          fullWidth
          onChange={(e) => setPhone(e.target.value)}
          sx={formPadding}
        />
        <TextField
          id="car_description"
          label="Car Make and Model"
          variant="outlined"
          fullWidth
          onChange={(e) => setCarDescription(e.target.value)}
          sx={formPadding}
        />
        <TextField
          id="notes"
          label="Notes"
          variant="outlined"
          fullWidth
          onChange={(e) => setNotes(e.target.value)}
          sx={formPadding}
        />
        {price > 0 && (
          <Typography variant="h6" component="div" gutterBottom sx={formPadding}>
            Price: ${price}
          </Typography>
        )}

        {!location.latitude && !location.longitude ? (
          <Button
            variant="contained"
            color="success"
            onClick={() => getLocation()}
          >
            {isFetchingLocation ? <CircularProgress size={24} color="inherit" /> : 'Get Location'}
          </Button>
        ) : (
          // <Button variant="contained" color="success" onClick={handleSubmit}>
          //   Request Tow
          // </Button>
          <PaypalCheckoutButton product={product} setRequestSent={setRequestSent} setTowDialogOpen={setTowDialogOpen} handleSubmit={handleSubmit} />
        )}
      </Stack>
    </Box>
  );
};

export default NewTowForm;
