import { FormControl, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material'
import React from 'react'

const SubscriptionOptions = () => {
  const product = {
    
  }
  return (
    <FormControl>
        <RadioGroup
            aria-label="subscription"
            name="subscription-buttons-group"
            >
            <FormControlLabel value="Gold" control={<Radio />} label="Gold" />
            <Typography>$30/month - 24/7 roadside assistance and towing for up to 50 miles, ensuring a safe and reliable solution to unexpected vehicle breakdowns.</Typography>
            <FormControlLabel value="Platinum" control={<Radio />} label="Platinum" />
            <Typography>$40/month - Benefits of the Gold Tier, with increased towing distance of up to 100 miles, lockout services, fuel delivery, and jump-starts for added peace of mind and convenience.</Typography>
            <FormControlLabel value="Diamond" control={<Radio />} label="Diamond" />
            <Typography>$60/month - Includes all the features of the Platinum Tier, along with priority service, flat tire repairs, trip interruption coverage, and additional perks such as discounts on car rentals and hotel stays for comprehensive support during any emergency.</Typography>
            </RadioGroup>
    </FormControl>
  )
}

export default SubscriptionOptions